import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import './jobs-header.sass'

export function JobsHeader({ className }) {
  return (
    <div className={`jobs-header ${className}`}>
      <Link to="/">
        <StaticImage
          src="../../../assets/common__logo.png"
          alt="Ogram: Find Part-Time Jobs"
          placeholder="blurred"
          className="jobs-header__logo"
        />
      </Link>
    </div>
  )
}