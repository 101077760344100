class GlobalSessionStorage {
  getItem(key) {
    if (typeof window === 'undefined') return null;
    return window.sessionStorage.getItem(key);
  }

  setItem(key, value) {
    if (typeof window === 'undefined') return;
    return window.sessionStorage.setItem(key, value);
  }

  removeItem(key) {
    if (typeof window === 'undefined') return;
    return window.sessionStorage.removeItem(key);
  }
}

export const globalSessionStorage = new GlobalSessionStorage();
