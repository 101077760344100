import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { StateContext, StateDispatchContext, actions } from '../state'
import { Dropdown } from '../../../components/dropdown'
import { JobProfessionFilter } from './job-profession-filter'
import './jobs-filters.sass'

export function JobsFilters({
  country,
  addressOptions,
  selectedMarket,
  markets,
  onMarketSelected,
  onAddressSelected,
  className,
}) {
  const { selectedAddress } = React.useContext(StateContext);
  const dispatch = React.useContext(StateDispatchContext);
  const { t } = useTranslation();
  return (
    <div className={`jobs-filters ${className}`}>
      <ul className="jobs-filters__list">
        <li className="jobs-filters__item">
          <Dropdown
            label={t(`${country}.jobs.country`)}
            selectedOption={selectedMarket}
            options={markets}
            onOptionSelected={onMarketSelected}
            getItemKey={item => item.id}
            getItemName={item => item.name}
            getItemIcon={item => item.icon}
          />
        </li>
        <li className="jobs-filters__item">
          <Dropdown
            label={t(`${country}.jobs.city`)}
            selectedOption={selectedAddress}
            options={addressOptions}
            onOptionSelected={address => {
              dispatch({ type: actions.SET_ADDRESS_FILTER, address });
              onAddressSelected(address.toLowerCase());
            }}
            getItemKey={item => item}
            getItemName={item => item}
            getItemIcon={null}
          />
        </li>
        <li className="jobs-filters__item">
          <JobProfessionFilter country={country} />
        </li>
      </ul>
    </div>
  )
}