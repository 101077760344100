import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { useOutsideClick } from '../../hooks/use-outside-click'
import './index.sass'

export function Dropdown({
  label,
  selectedOption,
  options,
  onOptionSelected,
  getItemKey,
  getItemName,
  getItemIcon,
}) {
  const dropdownRef = React.useRef();
  useOutsideClick(dropdownRef, () => {
    dropdownRef.current.open = false
  });

  return (
    <details className="dropdown" ref={dropdownRef}>
      <summary className="dropdown__external-button">
        <div className="dropdown__label">{label}</div>
        <div className="dropdown__value-container">
          <div className="dropdown__value-left">
            {selectedOption !== null && (
              <>
                {getItemIcon !== null && (
                  <img
                    src={getItemIcon(selectedOption)}
                    className="dropdown__flag-img"
                    alt=""
                  />
                )}
                <div
                  key={getItemKey(selectedOption)}
                  className="dropdown__value-selected-item"
                >
                  {getItemName(selectedOption)}
                </div>
              </>
            )}
          </div>
          <StaticImage
            src="../../assets/app/arrow-down.png"
            alt=""
            className="dropdown__arrow-img"
          />
        </div>
      </summary>
      <div className="dropdown__popup">
        <ul className="dropdown__list">
          {options.map(item => {
            const isSelected = getItemKey(selectedOption) === getItemKey(item)
            return (
              <li
                key={getItemKey(item)}
                className={`dropdown__item ${isSelected ? 'dropdown__item_active' : ''}`}
                onClick={() => {
                  onOptionSelected(item);
                  dropdownRef.current.open = false;
                }}
              >
                {getItemName(item)}
              </li>
            )
          })}
        </ul>
      </div>
    </details>
  )
}