export function formatTimestamp(timestamp, language) {
  const options = {
    weekday: 'short',
    month: 'short',
    year: '2-digit',
    day: '2-digit'
  };
  const date = new Date(timestamp);
  return date.toLocaleDateString(language, options).replace(',', '');
}
