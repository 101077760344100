import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Map, RenderingType, useApiIsLoaded, AdvancedMarker } from '@vis.gl/react-google-maps'
import { useTranslation } from 'react-i18next'

import { windowGlobal } from '../../../utils/window-global'
import { formatTimestamp } from '../../../utils/dates'
import './job.sass'

export function Job({ country, job, language, onGetJobPress }) {
  const apiIsLoaded = useApiIsLoaded();
  const { t } = useTranslation();
  return (
    <div className="job">
      <div className="job__header">
        <div className="job__row job__header-left">
          <div
            className="job__back-container"
            onClick={() => {
              windowGlobal.history.back()
            }}
          >
            <StaticImage
              src="../../../assets/app/arrow-back.png"
              alt=""
              className="job__back-img"
            />
          </div>
          <div className="job__company-img-container">
            <StaticImage
              src="../../../assets/app/bag.png"
              alt=""
              className="job__company-img"
            />
          </div>
          <div>
            <div className="job__title">
              {job.work_category_name}
            </div>
            <div className="job__subtitle">
              {job.address}
              <StaticImage
                src="../../../assets/app/certified.png"
                alt=""
                className="job__certified-img"
              />
            </div>
          </div>
        </div>
        <div className="job__row job__header-right">
          <div className="job__income-container">
            <div className="job__row job__income-top">
              {/* 
                if job is of hourly type, we show daily rate in the header
                and if it's any other type, we show monthly rate in the header
              */}
              <div className="job__income">
                {job.rate_type === 1 ? job.daily_rate : job.monthly_rate}
              </div>
              {' '}
              <div className="job__currency">{job.currency}</div>
            </div>
            <div className="job__income-description">
              {job.rate_type === 1 ? t(`${country}.jobs.approximately_per_day`) : t(`${country}.jobs.approximately_per_month`)}
            </div>
          </div>
          <div className="job__get-job-action-container">
            <div className={`job__jobs-left-label ${job.shifts.left / job.shifts.total < 0.3 ? 'job__jobs-left-label_highlighted' : ''}`}>
              {t(`${country}.jobs.number_of_jobs_left_long`, {
                left: job.shifts.left,
                total: job.shifts.total,
              })}
            </div>
            <div
              onClick={onGetJobPress}
              className="job__get-job-btn"
            >
              {t(`${country}.jobs.get_job`)}
            </div>
          </div>
        </div>
      </div>
      <div className="job__main">
        <div className="job__section job__stats">
          <div className="job__stat">
            <StaticImage
              src="../../../assets/app/clock-light.png"
              alt=""
              className="job__stat-img"
            />
            <div className="job__stat-text">
              <div className="job__stat-label">
                {t(`${country}.jobs.hours_per_day`, { count: job.hours_per_day })}
              </div>
              <div className="job__stat-sublabel">
                {t(`${country}.jobs.estimated_hours`)}
              </div>
            </div>
          </div>
          {/* 
            if job is of hourly type, we show hourly rate in the stats
            and if it's any other type, we don't show it
          */}
          {job.rate_type === 1 && (
            <div className="job__stat">
              <StaticImage
                src="../../../assets/app/coins-light.png"
                alt=""
                className="job__stat-img"
              />
              <div className="job__stat-text">
                <div className="job__stat-label">
                  {t(`${country}.jobs.money_per_hour`, {
                    currency: job.currency,
                    count: job.hourly_rate,
                  })}
                </div>
                <div className="job__stat-sublabel">
                  {t(`${country}.jobs.money_per_day`, {
                    currency: job.currency,
                    count: job.daily_rate,
                  })}
                </div>
              </div>
            </div>
          )}
          <div className="job__stat">
            <StaticImage
              src="../../../assets/app/calendar-light.png"
              alt=""
              className="job__stat-img"
            />
            <div className="job__stat-text">
              <div className="job__stat-label">{formatTimestamp(job.start_date, language)} - {formatTimestamp(job.end_date, language)}</div>
              <div className="job__stat-sublabel">
                {t(`${country}.jobs.count_of_days`, { count: job.shifts_count })}
              </div>
            </div>
          </div>
        </div>
        <div className="job__section">
          <div className="job__section-title">
            {t(`${country}.jobs.schedule`)}
          </div>
          <div className="job__section-description">
            {t(`${country}.jobs.hours_per_day_long`, { count: job.hours_per_day })}
          </div>
        </div>
        <div className="job__section">
          <div className="job__section-title">
            {t(`${country}.jobs.about_job`)}
          </div>
          <div className="job__section-description">
            {job.instructions}
          </div>
        </div>
        <div className="job__section">
          <div className="job__section-title">
            {t(`${country}.jobs.transportations`)}
          </div>
          <div className="job__row job__transportation-container">
            {Boolean(job.transportation_provided) ? (
              <StaticImage
                src="../../../assets/app/transport-blue.png"
                alt=""
                className="job__transportation-icon"
              />
            ) : (
              <StaticImage
                src="../../../assets/app/transport-grey.png"
                alt=""
                className="job__transportation-icon"
              />
            )}
            <div className="job__section-description">
              {Boolean(job.transportation_provided) ? t(`${country}.jobs.provided`) : t(`${country}.jobs.not_provided`)}
            </div>
          </div>
        </div>
        {apiIsLoaded && (
          <div className="job__section job__map-section">
            <div className="job__section-title">
              {t(`${country}.jobs.location_title`)}
            </div>
            <Map
              renderingType={RenderingType.RASTER}
              style={{
                width: '100%',
                height: 238,
                borderRadius: 8,
                position: 'relative',
              }}
              gestureHandling="none"
              disableDefaultUI
              mapId="6ce88470c17a9b3"
              defaultZoom={15}
              defaultCenter={{ lat: job.location.lat, lng: job.location.lng }}
              keyboardShortcuts={false}

            >
              <AdvancedMarker
                position={{ lat: job.location.lat, lng: job.location.lng }}
              >
                <div className="job__map-raduis" />
              </AdvancedMarker>
              <div className="job__map-underline-label">
                {t(`${country}.jobs.location_description`)}
              </div>
            </Map>
          </div>
        )}
      </div>
      <div className="job__row job__action-panel">
        <div className="job__income-container">
          <div className="job__row job__income-top">
            {/* 
                if job is of hourly type, we show daily rate in the header
                and if it's any other type, we show monthly rate in the header
              */}
            <div className="job__income">
              {job.rate_type === 1 ? job.daily_rate : job.monthly_rate}
            </div>
            {' '}
            <div className="job__currency">{job.currency}</div>
          </div>
          <div className="job__income-description">
            {job.rate_type === 1 ? t(`${country}.jobs.approximately_per_day`) : t(`${country}.jobs.approximately_per_month`)}
          </div>
        </div>
        <div className="job__get-job-action-container">
          <div className={`job__jobs-left-label ${job.shifts.left / job.shifts.total < 0.3 ? 'job__jobs-left-label_highlighted' : ''}`}>
            {t(`${country}.jobs.number_of_jobs_left_long`, {
              left: job.shifts.left,
              total: job.shifts.total,
            })}
          </div>
          <div
            onClick={onGetJobPress}
            className="job__get-job-btn"
          >
            {t(`${country}.jobs.get_job`)}
          </div>
        </div>
      </div>
    </div>
  )
}
