import { createContext } from 'react';

export const actions = {
  APPLY_CATEGORIES_FILTER: 'APPLY_CATEGORIES_FILTER',
  RESET_CATEGORIES_FILTER: 'RESET_CATEGORIES_FILTER',
  SET_ADDRESS_FILTER: 'SET_ADDRESS_FILTER',
  REMOVE_ITEM_FROM_CATEGORY_FILTER: 'REMOVE_ITEM_FROM_CATEGORY_FILTER',
  SET_VISIBLE_JOBS: 'SET_VISIBLE_JOBS',
  SET_FILTERED_JOBS: 'SET_FILTERED_JOBS',
  SET_SCROLL_POSITION: 'SET_SCROLL_POSITION',
};

export function reducer(state, action) {
  switch (action.type) {
    case actions.SET_VISIBLE_JOBS:
      return { ...state, visibleJobs: action.visibleJobs };
    case actions.SET_FILTERED_JOBS:
      return { ...state, filteredJobs: action.filteredJobs };
    case actions.APPLY_CATEGORIES_FILTER:
      let filteredJobs = state.filteredJobs;
      let visibleJobs = state.visibleJobs;
      if (action.ids.size > 0) {
        const professionsNames = new Set(Array.from(action.ids).map(id => state.professionMap.get(id).name));
        let matchingJobs = state.jobs.filter(job => {
          return professionsNames.has(job.work_category_name);
        });
        if (state.selectedAddress !== null) {
          matchingJobs = matchingJobs.filter(job => job.address.toLowerCase() === state.selectedAddress.toLowerCase());
        }
        filteredJobs = matchingJobs;
        visibleJobs = matchingJobs.slice(0, 10);
      }
      return {
        ...state,
        selectedProfessionIds: action.ids,
        filteredJobs,
        visibleJobs,
      };
    case actions.RESET_CATEGORIES_FILTER:
      if (state.selectedAddress !== null) {
        const matchingJobs = state.jobs.filter(job => job.address.toLowerCase() === state.selectedAddress.toLowerCase());
        return {
          ...state,
          selectedProfessionIds: new Set(),
          filteredJobs: matchingJobs,
          visibleJobs: matchingJobs.slice(0, 10),
        };
      }
      return {
        ...state,
        selectedProfessionIds: new Set(),
        filteredJobs: state.jobs,
        visibleJobs: state.jobs.slice(0, 10),
      };
    case actions.REMOVE_ITEM_FROM_CATEGORY_FILTER:
      const newItems = new Set(state.selectedProfessionIds);
      newItems.delete(action.id);
      if (newItems.size > 0) {
        const professionsNames = new Set(Array.from(newItems).map(id => state.professionMap.get(id).name));
        let matchingJobs = state.jobs.filter(job => {
          return professionsNames.has(job.work_category_name);
        });
        if (state.selectedAddress !== null) {
          matchingJobs = matchingJobs.filter(job => job.address.toLowerCase() === state.selectedAddress.toLowerCase());
          return {
            ...state,
            selectedProfessionIds: new Set(),
            filteredJobs: matchingJobs,
            visibleJobs: matchingJobs.slice(0, 10),
          };
        }
        return {
          ...state,
          selectedProfessionIds: newItems,
          filteredJobs: matchingJobs,
          visibleJobs: matchingJobs.slice(0, 10),
        };
      } else {
        let matchingJobs = state.jobs
        if (state.selectedAddress !== null) {
          matchingJobs = matchingJobs.filter(job => job.address.toLowerCase() === state.selectedAddress.toLowerCase());
        }
        return {
          ...state,
          selectedProfessionIds: newItems,
          filteredJobs: matchingJobs,
          visibleJobs: matchingJobs.slice(0, 10),
        };
      }
    case actions.SET_ADDRESS_FILTER:
      let matchingJobs = state.jobs.filter(job => job.address === action.address);
      if (state.selectedProfessionIds.size > 0) {
        const professionsNames = new Set(Array.from(state.selectedProfessionIds).map(id => state.professionMap.get(id).name));
        matchingJobs = matchingJobs.filter(job => {
          return professionsNames.has(job.work_category_name);
        });
      }
      return {
        ...state,
        selectedAddress: action.address,
        filteredJobs: matchingJobs,
        visibleJobs: matchingJobs.slice(0, 10),
      };
    case actions.SET_SCROLL_POSITION:
      return { ...state, scrollPosition: action.scrollPosition };
  }
  throw new Error(`Unexpected action type: ${action.type}`);
}

export const StateContext = createContext(null);
export const StateDispatchContext = createContext(null);
