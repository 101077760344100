import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'

import { useOutsideClick } from '../../../hooks/use-outside-click'
import { StateContext, StateDispatchContext, actions } from '../state'
import './job-profession-filter.sass'

export function JobProfessionFilter({ country }) {
  const { selectedProfessionIds, professionMap } = React.useContext(StateContext);
  const dispatch = React.useContext(StateDispatchContext);
  const detailsRef = React.useRef();
  useOutsideClick(detailsRef, () => {
    detailsRef.current.open = false;
  });
  const { t } = useTranslation();
  const [localSelectedOptions, setLocalSelectedOptions] = React.useState(selectedProfessionIds);

  return (
    <details className="jobs-profession-filter" ref={detailsRef}>
      <summary className="jobs-profession-filter__external-button">
        <div className="jobs-profession-filter__label">{t(`${country}.jobs.categories`)}</div>
        <div className="jobs-profession-filter__value-container">
          <ul className="jobs-profession-filter__selected-list">
            {selectedProfessionIds.size > 0 && (
              Array.from(selectedProfessionIds).map(id => {
                const item = professionMap.get(id);
                return (
                  <li
                    key={item.id}
                    className="jobs-profession-filter__selected-item"
                    onClick={(ev) => {
                      ev.preventDefault();
                      dispatch({ type: actions.REMOVE_ITEM_FROM_CATEGORY_FILTER, id: item.id });
                      const newLocalSelectedOptions = new Set(localSelectedOptions);
                      newLocalSelectedOptions.delete(item.id);
                      setLocalSelectedOptions(newLocalSelectedOptions);
                    }}
                  >
                    <div className="jobs-profession-filter__selected-item-text">
                      {item.name}
                    </div>
                    <StaticImage
                      src="../../../assets/cross-white.png"
                      alt="remove"
                      placeholder="blurred"
                      className="jobs-profession-filter__close-icon"
                    />
                  </li>
                )
              })
            )}
          </ul>
          <StaticImage
            src="../../assets/app/arrow-down.png"
            alt=""
            className="dropdown__arrow-img"
          />
        </div>
      </summary>
      <div className="jobs-profession-filter__popup">
        <ul className="jobs-profession-filter__list">
          {Array.from(professionMap.values()).map(item => {
            const hasItem = localSelectedOptions.has(item.id)
            return (
              <li
                key={item.id}
                className={`jobs-profession-filter__item ${hasItem ? 'jobs-profession-filter__item_active' : ''}`}
                onClick={() => {
                  const newLocalSelectedOptions = new Set(localSelectedOptions);
                  if (hasItem) {
                    newLocalSelectedOptions.delete(item.id);
                  } else {
                    newLocalSelectedOptions.add(item.id);
                  }
                  setLocalSelectedOptions(newLocalSelectedOptions);
                }}
              >
                {item.name}
              </li>
            )
          })}
        </ul>
        <div className="jobs-profession-filter__buttons">
          <div
            className="jobs-profession-filter__button jobs-profession-filter__clear-btn"
            onClick={() => {
              dispatch({ type: actions.RESET_CATEGORIES_FILTER });
              setLocalSelectedOptions(new Set());
              detailsRef.current.open = false;
            }}
          >
            {t(`${country}.jobs.clear`)}
          </div>
          <div
            className="jobs-profession-filter__button jobs-profession-filter__apply-btn"
            onClick={() => {
              dispatch({ type: actions.APPLY_CATEGORIES_FILTER, ids: localSelectedOptions });
              detailsRef.current.open = false;
            }}
          >
            {t(`${country}.jobs.apply`)}
          </div>
        </div>
      </div>
    </details>
  )
}